@import '../../assets/styles/mixins';
@import '../../assets/styles/variables';

.panel-cefr-level {
  background: $white;
  box-shadow: 0 0 40px #0000001c;
  font-family: $open-sans;
  margin-bottom: 45px;
  color: $tuatara;

  &__title {
    padding: 20px;
    margin: 0;
    font-size: 22px;
    font-weight: bold;
    color: $mike-blue;
    text-align: center;

    @include desktop {
      text-align: left;
    }
  }

  &__item {
    border-top: 0.5px solid $iron;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;

    &-summary {
      display: flex;
    }

    &-arrow {
      margin: 3px 0 0;
      height: 38px;
      width: 32px;

      &-with-image {
        background: url('../../assets/images/practice-test/arrow.png') no-repeat;
        height: 100%;
        width: 100%;
      }
    }

    &-level {
      font-size: 26px;
      font-weight: bold;
      color: $malibu;
      margin: 0 15px;
      padding: 2px 5px;

      &--selected {
        color: $mike-blue;
        border: 2px solid $mike-blue;
        border-radius: 50%;
      }
    }

    &-title {
      line-height: 16px;
      padding: 3px 0 0;

      &--bold {
        font-weight: bold;
      }
    }

    &-dropdown {
      border: solid $denim;
      border-width: 0 1px 1px 0;
      display: inline-block;
      padding: 4px;
      align-self: center;
      margin-right: 1rem;

      &--up {
        transform: rotate(-135deg);
        -webkit-transform: rotate(-135deg);
      }

      &--down {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
      }
    }
  }

  &__item-content {
    display: none;
    padding: 24px;
    border-top: 0.5px solid $iron;
    background-color: $polar;

    &--active {
      display: block;
    }
  }
}
