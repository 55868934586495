// FONTS
$open-sans: 'Open Sans', arial, sans-serif;
$open-sans-bold: 'Open Sans Bold', arial, sans-serif;

// GRID
$container-witdth: 1158px;
$columns: 12;
$gutter-width: 10px;
$tiny-breakpoint: 500px;
$main-breakpoint: 980px;
$small-breakpoint: 740px;
$large-breakpoint: 1280px;
$mail-width: 769px;

// HEADER
$header-desktop-height: 36px;
$header-mobile-height: 50px;

// MOBILE
$mobile-side-margin: 3%;

// COLOURS
$black: #000;
$bastille: #2e2e2e;
$tuatara: #444;
$charcoal: #464646;
$armadillo: #4a4a4a;
$scorpion: #565656;
$steel: #666;
$gray: #7f7f7f;
$oslo-gray: #8b8c91;
$star-dust: #9e9e9e;
$dusty-gray: #9b9b9b;
$pumica: #c7c7c7;
$alto: #d2d2d2;
$iron: #d7d7d7;
$gainsboro: #d8d8d8;
$aqua-haze: #eef3f8;
$gallery: #eee;
$whisper: #ececec;
$silver-chalice: #aaa;
$zircon: #f4f9fe;
$wild-sand: #f4f4f4;
$alabaster: #fafafa;
$seashell: #f1f1f1;
$white: #fff;

$alice-blue: #f3f9ff;
$lavender: #e2eef9;
$mike-blue: #004277;
$lochmara: #0188d9;
$denim: #1e7cc8;
$midnight-blue: #194273;
$resolution-blue: #2d4471;
$astronaut: #24426f;
$astronaut-blue: #043862;
$tufts-blue: #3d7bc2;
$blue: #3479f6;
$ultramarine-blue: #4578fb;
$danube: #5b96c5;
$malibu: #81bcfc;
$summer-sky: #4795e6;
$united-nations-blue: #5b94e0;
$maya-blue: #73b3ff;
$jordy-blue: #8cbaf9;
$carolina-blue: #9cbce3;
$sail: #bcdef2;
$smalt: #023a82;
$regal-blue: #014277;
$catalina-blue: #00307e;
$dodger-blue: #0679ff;
$dodger-blue-light: #29b7f6;
$pattens-blue: #e0effe;
$curious-blue: $denim;
$link-water: #ecf3fa;
$polar: #d6e8f5;
$picton-blue: #50a1fc;
$deep-cerulean: #0077af;
$polar: #eaf3fb;

$aqua-deep: #005734;
$shamrock-green: #009757;
$chateau-green: #42945d;
$hippie-green: #589262;
$caribbean-green: #00d47a;

$orange-peel: #f49700;
$fire-bush: #e79b37;
$web-orange: #f0aa00;
$gold: #da8d2a;

$auburn: #6f2f2d;
$old-brick: #921e15;
$cg-red: #ea3323;
$mojo: #c0492e;
$torch-red: #ff002f;
$grenadier: #e22b00;
$red: #f00;

$primary-color: $blue;
$secondary-color: $mike-blue;

// Opacity
$denim-75: rgba($denim, 0.75);
$white-50: rgba($white, 0.5);

$mike-blue-40: rgba($mike-blue, 0.4);
