@import '../../../../assets/styles/variables';
@import '../../../../assets/styles/mixins';

.search-session-map {
  display: block;
  background-color: $link-water;

  @include mobile {
    position: fixed;
    top: 57px;
    left: 0;
    height: calc(100vh - 57px);
    width: 100vw;
    z-index: 1;
  }

  @include desktop {
    margin-top: 20px;
    position: relative;
    overflow: hidden;

    &::before {
      display: block;
      width: 100%;
      padding-top: calc(556 / 743) * 100%;
      content: '';
    }
  }

  &__loader {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 10;
  }

  &__iframe {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }

  &__tooltip {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 1;

    .search-session-item {
      &__address-name {
        font-weight: bold;
      }

      &__address-content {
        font-weight: 600;
      }
    }

    &-address {
      padding: 20px;
      color: $secondary-color;
      background-color: $white;

      @include desktop {
        margin-left: 25px;
        width: calc(100% - 50px);
      }
    }

    &-slider {
      padding: 10px 0 5px;

      .carousel__items {
        margin-left: 25px;
      }

      .carousel__item {
        width: 271px;
        margin-left: 20px;

        @include desktop {
          width: 300px;
        }
      }
    }

    &--skeleton {
      .search-session-item--skeleton {
        min-height: 145px;
      }

      .search-session-map__tooltip-slider {
        margin-bottom: 37px;
      }

      .carousel__item:first-child {
        margin-left: 0;
      }
    }
  }
}
